<template>
  <div>
    <div class="header">
      <div class="leftbox">
        <img src="@/assets/img/6pllogo.png" />
        <a href="javascript:;">{{td("登录")}}</a>
      </div>
      <div class="rightbox">
		<router-link class="register" :to="'/'">{{td('登录已有账号')}}</router-link>
      </div>
    </div>
    <div class="main">
      <div class="mainBox">
        <div class="leftBox">
          <div class="circle"></div>
        </div>
        <div class="rightBox">
          <div class="circle"></div>
        </div>
        <ForgetForm />
      </div>
    </div>
    <div class="footer">
      <p>{{td("六方国际  版权所有")}}</p>
    </div>
	
  </div>
</template>

<script>
import ForgetForm from "./forgetForm";
export default {
  name: "login",
  components: { ForgetForm },
};
</script>

<style scoped>
.header {
  height: 70px;
  padding: 0px 50px;
  background: #fff;
}
.header .leftbox {
  position: relative;
  width: 340px;
  height: 60px;
  display: inline-block;
  margin-top: 5px;

}
.header .leftbox img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 60px;
  width: 210px;
  max-width: 210px;
}
.header .leftbox a:nth-child(2) {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #ff8228;
  font-size: 20px;
  font-weight: 500;
}
.header .rightbox {
  position: relative;
  width: 184px;
  height: 60px;
  margin-top: 5px;
  display: inline;
  float: right;
}
.header .rightbox .server {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: black;
  font-size: 12px;
}
.header .rightbox .register {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  margin: 0 0px 0 20px;
  padding: 8px 10px;
  font-size: 14px;
  color: #fff;
  background: #e60012;
}
/* body */
.main {
  height: calc( 100vh - 120px);
  background-color: #55C2F1;
}
.mainBox {
 
  height: calc( 100vh - 120px);
  margin: 0 auto;
  display: flex;
}
.mainBox .leftBox {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle {
  width: 100%;
  height: 550px;
  background: url(../assets/img/loginbg.png) no-repeat ;
  background-size: 100% 100%;
}
/* body end */

/* 底部 */
.footer {
  height: 50px;
  line-height: 50px;
  font-size: 14px;
}
.footer p {
  text-align: center;
}
/* 底部end */


/* 110% */
@media screen and (max-width: 1800px) {
  .mainBox {
    transform: scale(0.85, 0.85);
    -webkit-transform: scale(0.95, 0.95);
    -moz-transform: scale(0.85, 0.85);
    -o-transform: scale(0.85, 0.85);
  }
  .circle {
    transform: scale(0.85, 0.85);
    -webkit-transform: scale(0.95, 0.95);
    -moz-transform: scale(0.85, 0.85);
    -o-transform: scale(0.85, 0.85);
  }
}

/* 125% */
@media screen and (max-width: 1620px) {
  .mainBox {
    transform: scale(0.75, 0.75);
    -webkit-transform: scale(0.95, 0.95);
    -moz-transform: scale(0.75, 0.75);
    -o-transform: scale(0.75, 0.75);
  }
   .circle {
    transform: scale(0.85, 0.85);
    -webkit-transform: scale(0.95, 0.95);
    -moz-transform: scale(0.85, 0.85);
    -o-transform: scale(0.85, 0.85);
  }
}
/* 150 */
@media screen and (max-width: 1500px) {
  .mainBox {
    transform: scale(0.65, 0.65);
    -webkit-transform: scale(0.95, 0.95);
    -moz-transform: scale(0.65, 0.65);
    -o-transform: scale(0.65, 0.65);
  }
   .circle {
    transform: scale(0.85, 0.85);
    -webkit-transform: scale(0.95, 0.95);
    -moz-transform: scale(0.85, 0.85);
    -o-transform: scale(0.85, 0.85);
  }
}

@media screen and (max-width: 1380px) {
  .mainBox {
    transform: scale(0.55, 0.55);
    -webkit-transform: scale(0.95, 0.95);
    -moz-transform: scale(0.55, 0.55);
    -o-transform: scale(0.55, 0.55);
  }
   .circle {
    transform: scale(0.55, 0.55);
    -webkit-transform: scale(0.95, 0.95);
    -moz-transform: scale(0.55, 0.55);
    -o-transform: scale(0.55, 0.55);
  }
}
</style>