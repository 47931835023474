<template>
  <div class="reginsterCon">
    <div class="reginster">
      <p class="title">{{ td("重置密码") }}</p>
      <el-form ref="registerForm" :model="registerForm" :rules="registerRules" label-position="top">
		  
        <!-- 输入密码 -->
        <el-form-item :label="td('确认密码')" class="password" prop="password">
          <el-input v-model="registerForm.password" prefix-icon="el-icon-s-goods" type="password" show-password
          :placeholder="td('请输入密码')"  @keyup.enter.native="handleRegister" />
        </el-form-item>
        <!-- 二次输入密码 -->
        <el-form-item :label="td('再次输入确认密码')" class="confirmPassword" prop="confirmPassword">
          <el-input v-model="registerForm.confirmPassword" prefix-icon="el-icon-s-goods" type="password" show-password
          :placeholder="td('再次输入密码')" name="password" @keyup.enter.native="handleRegister" />
        </el-form-item>
        <!-- 手机 -->
        <el-form-item :label="td('手机')" class="confirmPassword" prop="phonenumber" style="margin-bottom: 28px;">
          <el-input v-model="registerForm.phonenumber" prefix-icon="el-icon-s-goods" type="text" :placeholder="td('请输入手机号码')" name="password" @keyup.enter.native="handleRegister" >
			 <el-select v-model="registerForm.prefix" slot="prepend" placeholder="请选择" style="width: 100px;float: left;" @change="look(phoneval)">
			      <el-option v-for="(item,index) in phonecode" :label="item.phone_code" v-model="item.phoneVal" ></el-option>
			 </el-select>  
		  </el-input>	  
        </el-form-item>
        <!-- 验证码 -->
        <el-form-item class="code" prop="code" v-if="captchaOnOff">
          <el-input v-model="registerForm.code" :placeholder="td('请输入验证码')" @keyup.enter.native="handleRegister"/>
          <div class="codeimg">
			<el-button :disabled="disabled" @click="getCode" type="primary" v-if="timeswtich" >{{btntxt}}</el-button>
			<el-button :disabled="disabled" @click="getCode" type="info" v-if="timeswtich==false" >{{btntxt}}</el-button>
          </div>
        </el-form-item>
        
        <el-form-item class="showmsg">
          <el-button id="submit" @click="handleRegister" class="loginBtn" @keyup.enter.native="handleRegister">
            <span v-if="!loading" style="color: #FFF9EA;">{{ td("重置密码") }}</span>
            <span v-else>{{ td("注册中") }}...</span>
          </el-button>

        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getCodeImg, register,getCodetext,getRePassword } from "@/api/login";
import Cookies from "js-cookie";
export default {
  name: "registerForm",
  data() {
    return {
      registerForm: {
        username: '',
        password: '',
        confirmPassword: '',
        // nickName:'',
        phonenumber:'',
        // email: '',
        code: '',
        // uuid: '',
		prefix: "86",
      },
	  disabled:false,
	  timeswtich: true,
	  time:60,
	  btntxt:"获取验证码",
	  phonecode: [
		  {
			 "chineseName":"中国",
			 "phone_code":"+86",
			 "phoneVal":"86"
		  },{
			 "chineseName":"泰国",
			 "phone_code":"+66",
			 "phoneVal":"66"
		  },{
			 "chineseName":"菲律宾",
			 "phone_code":"+63",
			 "phoneVal":"63"
		  },{
			 "chineseName":"印度尼西亚",
			 "phone_code":"+62",
			 "phoneVal":"62"
		  },{
			 "chineseName":"马来西亚",
			 "phone_code":"+60",
			 "phoneVal":"60"
		  },{
			 "chineseName":"文莱",
			 "phone_code":"+673",
			 "phoneVal":"673"
		  },{
			 "chineseName":"新加坡",
			 "phone_code":"+65",
			 "phoneVal":"65"
		  },{
			 "chineseName":"越南",
			 "phone_code":"+84",
			 "phoneVal":"84"
		  },{
			 "chineseName":"柬埔寨",
			 "phone_code":"+855",
			 "phoneVal":"855"
		  },{
			 "chineseName":"老挝",
			 "phone_code":"+856",
			 "phoneVal":"856"
		  },
		],
	  
	  
      loading: false,
      captchaOnOff: true,
      codeUrl:'',
      
      // 下拉框测试
      value: '',
      // 下拉框测试end 
    }
  },
  computed:{
    registerRules() {
      const equalToPassword = (rule, value, callback) => {
      if (this.registerForm.password !== value) {
        callback(new Error(this.td("两次输入的密码不一致")));
      } else {
        callback();
      }
    };
    //验证密码的规则
    var checkEmail = (rule, value, cb) => {
      //验证密码的正则表达式（包含数字、字母大小写、特殊字符）
      const regEmail = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_.,/;:!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^.,/;:&*`~()-+=]+$)(?![0-9\\W_!@#.,/;:$%^&*`~()-+=]+$)(?![a-zA-Z0-9]+$)(?![a-zA-Z\\W_!@#$%.,/;:^&*`~()-+=]+$)(?![a-z0-9\\W_!@#$%.,/;:^&*`~()-+=]+$)(?![0-9A-Z\\W_!@#.,/;:$%^&*`~()-+=]+$)[a-zA-Z0-9\\W_!@#$.,/;:%^&*`~()-+=]{8,30}$/;
      if (regEmail.test(value)) {
        //合法密码
        return cb();
      }
      cb(new Error( this.td("密码须包含大小写字母、数字以及特殊字符，且长度在8到30位")));
    };
    //验证电话号码
    var checkEmails = (rule, value, cb) => {
      const regEmail = /^[0-9]*$/;
      if (regEmail.test(value)) {
        //合法密码
        return cb();
      }
      cb(new Error( this.td("请输入正确的手机号码")));
    };
    //验证邮箱
    //验证邮箱的规则
    var checkEmaila = (rule, value, cb) => {
      //验证邮箱的正则表达式
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (regEmail.test(value)) {
        //合法邮箱
        return cb();
      }
      cb(new Error("请输入合法的邮箱"));
    };
      return  {
        username: [
          { required: true , message:this.td("请输入用户名") , trigger: "blur" },
          { min: 6, max: 20, message:this.td("用户账号长度必须介于 6 和 20 之间") , trigger: 'blur' }
        ],
        password: [
          { required: true , message:this.td("请输入密码") , trigger: "blur" },
          { validator: checkEmail, trigger: "blur" }
        ],
        confirmPassword: [
          { required: true , message:this.td("再次输入密码") , trigger: "blur"},
          {  trigger: "blur", validator: equalToPassword }
        ],
        nickName: [
          { required: true , message:this.td("请输入昵称") , trigger: "blur" },
        ],
        phonenumber: [
           { required: true , message:this.td("请输入手机号码") , trigger: "blur" },
           { validator: checkEmails, trigger: "blur" }
        ],

        // code: [
        //   { required: true, trigger: "change", message: this.td("请输入验证码"), trigger: "blur"}
        // ]
      }
    }
  },
  created() {
    // this.getCode();
  },
  methods: {
    getCode() {
      // getCodeImg().then(res => {
      //   this.captchaOnOff = res.captchaOnOff === undefined ? true : res.captchaOnOff;
      //   if (this.captchaOnOff) {
      //     this.codeUrl = "data:image/gif;base64," + res.img;
      //     this.registerForm.uuid = res.uuid;
      //   }
      // });
	  if(this.registerForm.phonenumber == '' || this.registerForm.phonenumber == null){
		this.$message({message: '请输入手机号码',type: 'error'});
	  }else {
		getCodetext(this.registerForm.phonenumber,this.registerForm.prefix).then(res => {
		  // this.captchaOnOff = res.captchaOnOff === undefined ? true : res.captchaOnOff;
		  // if (this.captchaOnOff) {
		  //   this.codeUrl = "data:image/gif;base64," + res.img;
		  //   this.registerForm.uuid = res.uuid;
		  // }console.log(res);
		   
		});
		this.time = 60;
		this.timer(); 
	  }
	  
    },
	timer() {
		 const registTime = Cookies.get("registertime");
		 this.timeswtich = false
		 if(registTime == null){
		 	// Cookies.set("registertime",this.time);
			this.disabled = true;
			this.time--;
			Cookies.set("registertime",this.time);
			this.btntxt = this.time + "秒重新获取";
			// this.timeswtich = false;
			setTimeout(this.timer, 1000);
		 }else {
		 	// this.time = Cookies.get("registertime");
			if(registTime > 0){
				this.time = Cookies.get("registertime");
			}
		 	if(this.time > 0) {
		 	 this.disabled = true;
		 	 this.time--;
		 	 Cookies.set("registertime",this.time);
		 	 this.btntxt = this.time + "秒重新获取";
		 	 setTimeout(this.timer, 1000);
		 	} else{
		 	 this.time = 0;
			 this.timeswtich = true
		 	 this.btntxt = "获取验证码";
		 	 this.disabled=false;
		 	}
		 }
	 },
    handleRegister() {
      // this.$refs.registerForm.validate(valid => {
        // if (valid) {
          this.loading = true;
		  // let arr = new Array;
		  // arr.push(this.registerForm)
		  // console.log(arr);
          getRePassword(this.registerForm).then(res => {
            // const username = this.registerForm.username;
            this.$alert("<font color='red'>" + this.td("重置密码成功")+"！</font>", this.td("系统提示"), {//111
              dangerouslyUseHTMLString: true
            }).then(() => {
              this.$router.push("/");
            }).catch(() => {});
          }).catch(() => {
            this.loading = false;
            if (this.captchaOnOff) {
              // this.getCode();
            }
          })
        // }
      // });
    }
  },
};
</script>

<style scoped>
 .reginsterCon {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reginsterCon .reginster {
  
  width: 420px;
  background: #fff;
  padding: 48px 32px;
  box-sizing: border-box;
}
 .reginsterCon .reginster .title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  text-align: center;
}


.el-form .el-select {
  display: inline-block;
}

.el-form .code .el-input {
  width: 215px;
  float: left;
}
.el-form .code .codeimg {
  height: 40px;
  float: left;
  padding-left: 20px;
}
.el-form .code img {
  height: 40px;
  margin-left: 15px;
  
}
.el-form .showmsg .el-button {
  margin-top: 20px;
  width: 100%;
  background: linear-gradient(100deg, red 0%, #67A5E0 80%);
}
/* .el-form .showmsg .attention {
  margin-top: 20px;
  text-align: center;
} */

.reginsterCon ::v-deep .el-form-item__label {
  height: 30px;
}

/* .reginsterCon ::v-deep .el-form-item__error {
  margin-bottom: 5px;
} */
.reginsterCon .el-form-item {
  margin-bottom: 8px;
}

/* 110% */
@media screen and (max-width: 1800px) {
  .reginsterCon {
    width: 420px;

  }
  .reginsterCon {
    transform: scale(0.85, 0.85);
    -webkit-transform: scale(0.85, 0.85);
    -moz-transform: scale(0.85, 0.85);
    -o-transform: scale(0.85, 0.85);
  }
}

/* 110% */
@media screen and (max-width: 1620px) {
  .reginsterCon {
    width: 420px;

  }
  .reginsterCon {
    transform: scale(0.75, 0.75);
    -webkit-transform: scale(0.75, 0.75);
    -moz-transform: scale(0.75, 0.75);
    -o-transform: scale(0.75, 0.75);
  }
}

@media screen and (max-width: 1500px) {
  .reginsterCon {
    width: 420px;

  }
  .reginsterCon {
    transform: scale(0.65, 0.65);
    -webkit-transform: scale(0.65, 0.65);
    -moz-transform: scale(0.65, 0.65);
    -o-transform: scale(0.65, 0.65);
  }
}

@media screen and (max-width: 1380px) {
  .reginsterCon,.reginster {
    width: 600px;
  }
  .reginsterCon {
    transform: scale(0.55, 0.55);
    -webkit-transform: scale(0.65, 0.6);
    -moz-transform: scale(0.55, 0.55);
    -o-transform: scale(0.55, 0.55);
  }
}
	.el-button--info{
		padding: 12px !important;
	}
</style>